/**************** padding and margin settings *****************

	Borrowed From Kianosh Pourian's excellent "Sass in Depth" book
	https://github.com/kianoshp/SASS-CSS-Boilerplate/blob/master/sass/padding-margin.scss

	Setting padding and margins to be added to different sections
	This sections contains individual setting and also a mixin
	to be able to add to different sections

	spacing helpers
	p,m = padding,margin
	a,t,r,b,l,v,h = all,top,right,bottom,left,vertical,horizontal
	s,m,l,n = small(5px),medium(10px),large(20px),none(0px)

	This scss will create an example of the following:
	.pts, .pas, .pvs {
  		padding-top: 5px !important; }

	.ptm, .pam, .pvm {
  		padding-top: 10px !important; }

	.ptl, .pal, .pvl {
  		padding-top: 20px !important; }

**************************************************************/
$paddingMargins: p, m;
$positions: t, r, b, l;
$padding-increment: 1.4rem;
$sizes: s, m, l, n;
$pm: padding;
$pos: top;
$sz: 0;
$hvpos: v;
@each $paddingMargin in $paddingMargins {
	$tempVar : $paddingMargin;
	@if $paddingMargin == "p" {
		$pm: padding;
	} @else {
		$pm: margin;
	}
	@each $position in $positions {
		$tempVar2: #{$tempVar}#{$position};
		@if $position == "t" {
			$pos: top;
			$hvpos: v;
		} @else if $position == "r" {
			$pos: right;
			$hvpos: h;
		} @else if $position == "b" {
			$pos: bottom;
			$hvpos: v;
		} @else if $position == "l" {
			$pos: left;
			$hvpos: h;
		}
		@each $size in $sizes {
			$tempVar3: #{$tempVar2}#{$size};
			@if $size == "s" {
				$sz: $padding-increment; 
			} @else if $size == "m" {
				$sz: $padding-increment * 2;
			} @else if $size == "l" {
				$sz: $padding-increment * 4;
			} @else if $size == "n" {
				$sz: $padding-increment * 0;
			}
			.#{$tempVar3}, .#{$tempVar}a#{$size}, .#{$paddingMargin}#{$hvpos}#{$size} {
				#{$pm}-#{$pos}: $sz !important;
			} 
		}
	}
}
/***************** end padding and margin settings *************/