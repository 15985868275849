.footer-content {
  @media (max-width: $screen-xs-max) {
    padding: 0 ($grid-gutter-width*2);
  }

}

.site-footer-title {
  font-size: 2rem;
  font-weight: 400;
  font-family: $headings-font-family-alt;
  span {
    font-weight: 700;
    font-family: $headings-font-family;
  }
}

.footer-content p {
  font-size: .928em;
}
.delimit {
  @media (min-width: $screen-lg-min) {
    font-weight: 700;
    padding: 0 1rem;
  }
}

.footer-widget:not(:last-child) {
  @media (max-width: $screen-xs-max) {
    padding-bottom: 4rem;
  }
  @media (min-width: $screen-sm-min) {
    padding-right: $grid-gutter-width*4;
  }
}
