body {
  //padding-top: $navbar-height;
}

.navbar {
  margin-bottom: 0;
}

.navbar-nav > li > a {
  text-transform: uppercase;
  font-family: $headings-font-family-alt;
  font-weight: normal;
  @media (min-width: $screen-sm-min) {
    font-size: 1.2rem;
  }
  @media (max-width: $screen-xs-max) {
    font-size: 1.5rem;
    text-align: center;
  }
}

@media (max-width: $screen-xs-max) {
  .navbar-brand {
    max-width: 80%;
    .logo {
      max-width: 80%;
    }
  }
}

.primary-nav {
  padding-left: 0;
  list-style: none;
  text-align: center;
  line-height: 1.1;
  @media (min-width: $screen-sm-min) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 54px;
  }
  li {
    font-family: $headings-font-family-alt;
    font-weight: normal;
    //font-size: 1.285em;
    list-style: none;
    @media (min-width: $screen-sm-min) {
      order: 1;
      flex-grow: 1;
      flex-basis: auto;
      position: relative;
      min-width: 15%;
    }
    @media (max-width: $screen-xs-max) {
      width: 100%;
      margin: 0;
      height: auto;
    }
    text-align: center;
    a {
      font-weight: 400;
      letter-spacing: 1px;
      background: $gray-dark;
      @media (min-width: $screen-sm-min) {
        order: 1;
      }
      padding: 1.5rem;
      margin: 0 2px;
      color: $white;
      display: block;
      text-transform: uppercase;
      @media (max-width: $screen-xs-max) {
        display: block;
        margin-bottom: $grid-gutter-width/2;
      }
      &:hover {
        text-decoration: none;
        color: $white;
        background: darken($gray-dark, 15%);
      }
    }

    .sub-menu {
      @media (min-width: $screen-sm-min) {
        display: none;
        width: 100%;
        position: absolute;
        left: 0;
      }
      padding: 0;
      @media (max-width: $screen-xs-max) {
        display: block;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      }
      li {
        font-size: .9em;
        //width: 100%;
        a {
          text-transform: none;
          letter-spacing: 0;
          padding: .75rem;
        }
      }
    }
    &:hover > .sub-menu {
      display: inline-block;
    }
  }
}

.current-menu-ancestor.menu-item-has-children,
.current-menu-parent.menu-item-has-children {
  a {
    background: darken($gray-dark, 15%);
    text-decoration: underline;
  }
  li a {
    text-decoration: none;
  }
}

.navbar-default {
  @media (min-width: $screen-sm-min) {
    margin-top: 1.5rem;
  }
  .navbar-right > li > a:hover {
    text-decoration: underline;
  }
}

.dropdown {
  z-index: 1050;
}

.site-title {
  @media (max-width: $screen-xs-max) {
    padding: ($grid-gutter-width/2);
  }

}

.site-title-heading {
  font-family: $headings-font-family-alt;
  font-weight: normal;
  @media (max-width: $screen-xs-max) {
    font-size: 3rem;
  }
  @media (min-width: $screen-sm-min) {
    font-size: 6rem;
  }
  margin-bottom: 0;
  &:hover {
    text-decoration: none;
    color: $teal;
  }
  @media (max-width: $screen-xs-max) {
    padding: 0 ($grid-gutter-width*2);
  }

}


