.search-form {
  @extend .form-group;
}

input.search-field {
  @extend .form-control;
  @extend .form-control;
}

.sidebar-search-checkbox {
  position: relative;
  margin-bottom: $grid-gutter-width;
  @media (max-width: $screen-xs-max) {
    margin-left: $grid-gutter-width*2;
  }
  @media (min-width: $screen-sm-min) {
  }
  .checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
    margin-top: 2px;
  }
}

.sidebar input[type=submit] {
  @extend .btn-default;
}

.sidebar h2,
.sidebar h3 {
  font-family: $headings-font-family-thin;
}

.search-frontpage-checkbox {
  position: relative;
  margin-bottom: $grid-gutter-width;
  @media (max-width: $screen-xs-max) {
    margin-left: $grid-gutter-width*2;
  }
  @media (min-width: $screen-sm-min) {
  }
  .checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
    margin-top: 2px;
    display: inline;
  }
}

@media (min-width: $screen-sm-min) {
  .search-frontpage {
    text-align: center;
  }
  .search-frontpage-checkboxes {
    margin-left: $grid-gutter-width*2;
  }
  .search-frontpage-checkbox {
    display: inline-block;
    margin-left: $grid-gutter-width*2;
    margin-right: $grid-gutter-width*2;
  }
}