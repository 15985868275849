.container .jumbotron {
  @media (max-width: $screen-xs-max) {
    padding: ($grid-gutter-width*2);
  }
}

.lydsamlinger {
  list-style: none;
  padding: 0;
}

.lydsamling {
  padding: 0;
  a {
    @media (max-width: $screen-xs-max) {
      padding: ($grid-gutter-width) 0;
    }
    @media (min-width: $screen-sm-min) {
      padding: ($grid-gutter-width/2) 0;
    }
    display: block;
  }
}