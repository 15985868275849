.icon {
  border-radius: 2px;
}

.icon-down {
  fill: $text-color;
  width: 14px;
  height: 14px;
  padding: 2px;
  margin-left: .5rem;
}

.icon-left,
.icon-right {
  fill: white;
  width: 18px;
  height: 18px;
  padding: 2px 2px 2px 6px;
  background: $brand-primary;
  &:hover {
    background: $brand-primary;
  }
}

.icon-right-list {
  fill: $brand-primary;
  width: 14px;
  height: 14px;
  padding: 4px 2px 0 0;
}

.icon-pagination {
  width: 24px;
  height: 24px;
  fill: $gray-light;
  background: $white;
  padding: 0;
  margin: 8px 0 0;
  &:hover {
    background: $white;
    fill: $text-color;
  }
}

.icon-pagination-disabled {
  &:hover {
    fill: $gray-light;
  }
}

.icon-lyd {
  fill: $text-color;
}