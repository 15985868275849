.english-toggle[aria-expanded="true"]:focus {
  color: $gray-lighter;
}

.english-toggle[aria-expanded="false"] {
  //color: $text-color;
}

.lyt {
  width: 36px;
}

.kaltura-panel {
  padding: $grid-gutter-width ($grid-gutter-width*2);
}

.kaltura-title {
  font-size: 1.12em;
  overflow: hidden;
  a {
    font-family: $headings-font-family-thin;
    margin: 0 0 .15em;
    font-weight: normal;
  }
}

.kaltura-meta {
  @extend .small;
  margin: 0;
}

.kaltura-nav {
  background: $white;
  padding: ($grid-gutter-width) ($grid-gutter-width*2) ($grid-gutter-width/2);
}

.kaltura-pagination {
  list-style: none;
  margin: 0;
  color: $gray-light;
  a {
    display: inline-block;
  }
}

.kaltura-pagination-number {
  line-height: 2.75;
  vertical-align: top;
  padding: 0 1em;
}

.kaltura-lydoptagelser {
  list-style: none;
  padding: 0;
  li {
    background: url(../images/sprite.png) no-repeat -50px -46px;
    padding: 0 0 1em 12px;
  }
}

.kaltura-player {
  @extend .embed-responsive-item;
}

.kaltura-multi-player {
  @media (max-width: $screen-xs-max) {
    li {
      padding: .5em 0;
    }
  }
}

.lyt {
  height: 16px;
  width: 16px;
  margin: 0 24px;
}