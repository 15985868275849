@font-face {
  font-family: 'MuseoSans-700';
  src: url('../fonts/300B46_0_0.eot');
  src: url('../fonts/300B46_0_0.eot?#iefix') format('embedded-opentype'), url('../fonts/300B46_0_0.woff2') format('woff2'), url('../fonts/300B46_0_0.woff') format('woff'), url('../fonts/300B46_0_0.ttf') format('truetype');
font-style: normal;
font-weight: normal;
}

@font-face {
  font-family: 'MuseoSans-500';
  src: url('../fonts/300B46_3_0.eot');
  src: url('../fonts/300B46_3_0.eot?#iefix') format('embedded-opentype'), url('../fonts/300B46_3_0.woff2') format('woff2'), url('../fonts/300B46_3_0.woff') format('woff'), url('../fonts/300B46_3_0.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'MuseoSans-900';
  src: url('../fonts/300B46_9_0.eot');
  src: url('../fonts/300B46_9_0.eot?#iefix') format('embedded-opentype'), url('../fonts/300B46_9_0.woff2') format('woff2'), url('../fonts/300B46_9_0.woff') format('woff'), url('../fonts/300B46_9_0.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

label {
  font-weight: 400;

}

// Links
a {
  color: $link-color;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    @include tab-focus;
  }
}

.content-links a {
  text-decoration: none;
  color: $brand-primary;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.text-links a {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.meta a,
.entry-meta a {
  color: $gray-light;
}

.panel {
  padding: $grid-gutter-width*2.5;
  margin-bottom: $grid-gutter-width;
}

.callout {
  background: $gray-darker;
  color: $white;
  font-size: .85em;
  a {
    color: $white;
    &:hover {
      color: $brand-primary;
      text-decoration: none;
    }
  }
}

.readmore {
  font-weight: 700;
}

.well {
  color: $gray-light;
}

.container .jumbotron {
  border-radius: 0;
  margin-bottom: ($grid-gutter-width);
}

.sub-footer {
  line-height: 1.4;
  @media (max-width: $screen-xs-max) {
    .btn {
      margin-bottom: 3rem;
    }
  }
}

.meta {
  color: $gray-light;
}

.search-list {
  padding-top: 2rem;
  border-top: 1px solid $gray-lighter;
  margin-top: 2rem;
}

.search-list-item {
  padding-bottom: 2rem;
  border-bottom: 1px solid $gray-lighter;
  margin-bottom: 2rem;
}
